import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import * as footerStyles from "./footer.module.css"
//import {StaticImage} from "gatsby-plugin-image"
import {AiOutlineTwitter} from "react-icons/ai"
import {MdEmail, MdCopyright} from "react-icons/md"
import {IconContext} from "react-icons"

const Footer = ({ style }) => (
  <footer className={footerStyles.wrapper} style={style}>
    <div className={footerStyles.copyright}>
        <IconContext.Provider value={{ style: {verticalAlign: "middle", alignSelf: "center", marginRight: ".25em"}, size: "1.5em" }}>
            <MdCopyright />
        </IconContext.Provider>
        <span style={{verticalAlign: "middle"}}>{new Date().getFullYear()} CRE3D Controllers</span>
    </div>
    <div className={footerStyles.contactWrapper}>
        <div
        className={footerStyles.twitterWrapper}
        >
            <a
            href="https://twitter.com/Cre3dController"
            >
            <IconContext.Provider value={{ style: {color: "#1da1f2", verticalAlign: "middle", alignSelf: "center", marginRight: ".25em"}, size: "1.5em" }}>
                <AiOutlineTwitter />
            </IconContext.Provider>
            <span style={{verticalAlign: "middle"}}>Follow on Twitter for updates!</span>
            </a>
        </div>
        <div className={footerStyles.emailWrapper}>
            <a href = "mailto: cre3dcontrollers@gmail.com">
            <IconContext.Provider value={{ style: {verticalAlign: "middle", alignSelf: "center", marginRight:".25em"}, size: "1.5em" }}>
                <MdEmail />
            </IconContext.Provider>
                <span style={{verticalAlign: "middle"}}>CRE3DControllers@gmail.com</span></a>
        </div>
    </div>
  </footer>
)

Footer.propTypes = {
  style: PropTypes.object
}

Footer.defaultProps = {
  style: {},
}

export default Footer
