import * as React from "react"
import PropTypes from "prop-types"
import { Link, useStaticQuery, graphql } from "gatsby"
import * as headerStyles from "./header.module.css"
import {GatsbyImage, getImage} from "gatsby-plugin-image"

const Header = ({ style }) => {
  const data = useStaticQuery(
    graphql`
      query {
      imageSharp(resize: {originalName: {eq: "cre3d_logo_transparent_white.png"}}) {
        gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, JPG, WEBP, AVIF])
      }
    }
    `
  )
  return (
  <header className={headerStyles.wrapper} style={style}>
    <div
      style={{
        marginLeft: ".75rem",
        marginRight: ".75rem",
        width: "max-content",
        //flex: '1 1 min-content',
      }}
    >
        <Link
          to="/"
          style={{
          }}
        >
          <GatsbyImage 
            image={getImage(data.imageSharp.gatsbyImageData)}
            objectFit="contain"
            placeholder="none"
            className={headerStyles.logoWrapper}
            alt=""
            style={{ maxWidth: "8rem", width: "145px", minHeight: "80px", height: "80px", display: "block"}}
          />
        </Link>
    </div>
    <div style={{width: 'min-content', marginTop: "auto", marginBottom: "auto"}}>
      <ul className={headerStyles.navList}>
        <li><Link to="/overview/" className={headerStyles.navLink}>Overview</Link></li>
        <li><Link to="/FAQ/" className={headerStyles.navLink}>FAQ</Link></li>
        {/*<li><Link to="/Showcase" className={headerStyles.navLink}>Showcase</Link></li>*/}
        <li><Link className={headerStyles.navLink} to="/blog/">Blog</Link></li>
        <li><Link to="/buy" className={headerStyles.navLink}>Buy</Link></li>
      </ul>
    </div>
  </header>
)}

Header.propTypes = {
  style: PropTypes.object
}

Header.defaultProps = {
  style: {},
}

export default Header
